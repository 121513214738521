/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CrossProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Cross: React.SFC<CrossProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M12.667 2.667q0.286 0 0.477 0.19t0.19 0.477q0 0.281-0.193 0.474l-4.198 4.193 4.198 4.193q0.193 0.193 0.193 0.474 0 0.286-0.19 0.477t-0.477 0.19q-0.281 0-0.474-0.193l-4.193-4.198-4.193 4.198q-0.193 0.193-0.474 0.193-0.286 0-0.477-0.19t-0.19-0.477q0-0.281 0.193-0.474l4.198-4.193-4.198-4.193q-0.193-0.193-0.193-0.474 0-0.286 0.19-0.477t0.477-0.19q0.281 0 0.474 0.193l4.193 4.198 4.193-4.198q0.193-0.193 0.474-0.193z"
    />
  </svg>
);
Cross.displayName = 'Cross';
/* tslint:enable */
/* eslint-enable */
