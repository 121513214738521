/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CloseWithBackgroundProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const CloseWithBackground: React.SFC<CloseWithBackgroundProps> = ({size, ...props}) => (
  <svg viewBox="0 0 32 32" fill="currentColor" width={ size || "32" } height={ size || "32" } {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="16" cy="16" r="16" />
      <path d="M18.7692308,4 L20,5.23076923 L13.23,12 L20,18.7692308 L18.7692308,20 L12,13.23 L5.23076923,20 L4,18.7692308 L10.769,12 L4,5.23076923 L5.23076923,4 L12,10.769 L18.7692308,4 Z"
        transform="translate(4 4)" fill="currentColor" />
    </g>
  </svg>
);
CloseWithBackground.displayName = 'CloseWithBackground';
/* tslint:enable */
/* eslint-enable */
