import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import cx from 'classnames';
import s from './ModalGallery.scss';
import {IMedia} from '@wix/wixstores-client-core/dist/src/types/product';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ArrowLeft, ArrowRight} from '../../icons/dist';
import {CloseWithBackground} from '../../icons/dist/components/CloseWithBackground';
import {preventMultiTouch} from './utils/preventMultiTouch';

export interface ModalGalleryProps {
  currentIndex: number;
  handleClose(): void;
  handleNavigateTo(index: number): void;
  isMobile: boolean;
  media: IMedia[];
}

interface ModalGalleryState {
  width: number;
  height: number;
  zoom: boolean;
  zoomOffset: {x: number; y: number};
}

export enum Hooks {
  ArrowNext = 'modal-gallery-arrow-next',
  ArrowPrev = 'modal-gallery-arrow-prev',
  DotNavigation = 'modal-gallery-dots',
  Close = 'modal-gallery-close',
  Container = 'modal-gallery-container',
  Header = 'modal-gallery-header',
  MediaNode = 'modal-gallery-media-node',
  Root = 'modal-gallery-root',
}

export class ModalGallery extends React.Component<ModalGalleryProps, ModalGalleryState> {
  public root = React.createRef<HTMLDivElement>();
  public media = React.createRef<HTMLImageElement>();
  private readonly zoomFactor = 2;
  private readonly mediaOptions = {isSEOBot: true};
  private currentTouch = {x: 0, y: 0};
  private clicks = 0;

  public warmed: string[] = [];
  public state = {
    width: undefined,
    height: undefined,
    zoom: false,
    zoomOffset: {x: 0, y: 0},
  };

  public static defaultProps = {
    currentIndex: 0,
    handleClose: _.noop,
    handleNavigateTo: _.noop,
    isMobile: false,
    media: [],
  };

  public componentWillUnmount() {
    document.body.classList.remove(s.fixed);
  }

  public componentDidMount() {
    document.body.classList.add(s.fixed);
    this.setState(
      {
        width: this.root.current.offsetWidth || 500,
        height: this.root.current.offsetHeight || 500,
      },
      this.warmUp
    );
  }

  public render() {
    const {zoom} = this.state;
    const classNames = cx({
      [s.modal]: true,
    });

    return ReactDOM.createPortal(
      <div data-hook={Hooks.Root} className={classNames} ref={this.root}>
        {this.renderHeader()}
        {this.renderContainer()}
        {!zoom && this.renderFooter()}
      </div>,
      document.body
    );
  }

  private getScaledMedia(media: IMedia, zoom: number): {width: number; height: number} {
    const {height: containerHeight, width: containerWidth} = this.state;
    const mediaRatio = media.width / media.height;
    const containerRatio = containerWidth / containerHeight;
    const scale = mediaRatio > containerRatio ? media.width / containerWidth : media.height / containerHeight;

    return {width: Math.ceil((media.width / scale) * zoom), height: Math.ceil((media.height / scale) * zoom)};
  }

  private renderContainer() {
    const {media, currentIndex} = this.props;
    const {zoom: withZoom, zoomOffset} = this.state;
    const currentMedia = media[currentIndex];

    const mediaScales = this.currentMediaScales();
    const url = getMediaUrl(currentMedia, mediaScales, this.mediaOptions) as string;

    const nextX = -mediaScales.width / 2 - zoomOffset.x;
    const nextY = -mediaScales.height / 2 - zoomOffset.y;
    const transform = `translate3d(${nextX}px, ${nextY}px, 0)`;

    const classNames = cx({
      [s.container]: true,
      [s.zoom]: withZoom,
    });

    //tslint:disable jsx-no-bind
    return (
      <div className={s.box}>
        {!withZoom && this.renderArrows()}
        <div
          key={`modal-gallery-media-${url}`}
          className={classNames}
          data-current-media={url}
          data-hook={Hooks.Container}
          onTouchStart={preventMultiTouch(this.handleOnTouchStart.bind(this))}
          onTouchMove={preventMultiTouch(this.handleOnTouchMove.bind(this))}
          onTouchEnd={this.handleOnTouchEnd.bind(this)}
          onClick={() => this.handleClick()}>
          <img
            data-hook={Hooks.MediaNode}
            src={url}
            style={{transform}}
            width={mediaScales.width}
            height={mediaScales.height}
            ref={this.media}
          />
        </div>
      </div>
    );
    //tslint:enable jsx-no-bind
  }

  private renderHeader() {
    return (
      <header data-hook={Hooks.Header}>
        <a data-hook={Hooks.Close} onClick={e => this.handleClose(e)} className={s.close}>
          <CloseWithBackground />
        </a>
      </header>
    );
  }

  private handleClose(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleClose();
  }

  private renderFooter() {
    const {media, currentIndex} = this.props;

    return (
      <footer>
        <ul data-hook={Hooks.DotNavigation} className={s.dots}>
          {media.map((_m, i) => (
            <li className={cx({[s.navigation]: true, [s.selected]: currentIndex === i})}></li>
          ))}
        </ul>
      </footer>
    );
  }

  private handleOnTouchStart(event: React.TouchEvent) {
    this.currentTouch = {
      x: event.touches[0].clientX + this.state.zoomOffset.x,
      y: event.touches[0].clientY + this.state.zoomOffset.y,
    };
  }

  private handleOnTouchEnd(event: React.TouchEvent) {
    if (!this.state.zoom && event.changedTouches.length > 1) {
      return this.toggleZoom();
    }

    if (this.state.zoom) {
      return;
    }
    const momentumX = this.currentTouch.x - event.changedTouches[0].clientX;
    const momentumY = this.currentTouch.y - event.changedTouches[0].clientY;
    if (momentumY >= 200 || momentumY <= -200) {
      return this.props.handleClose();
    }
    if (momentumX >= 50) {
      return this.navigateNext();
    }
    if (momentumX <= -50) {
      return this.navigatePrev();
    }
  }

  private currentMediaScales(): {width: number; height: number} {
    const {media, currentIndex} = this.props;
    const zoom = this.state.zoom ? this.zoomFactor : 1;
    return this.getScaledMedia(media[currentIndex], zoom);
  }

  private handleOnTouchMove(event: React.TouchEvent) {
    if (!this.state.zoom) {
      return;
    }

    const x = event.changedTouches[0].clientX;
    const y = event.changedTouches[0].clientY;
    const zoomOffset = {x: this.currentTouch.x - x, y: this.currentTouch.y - y};
    const currentMediaScales = this.currentMediaScales();

    const boundryX = Math.abs((this.state.width - currentMediaScales.width) / 2) - 2;
    const boundryY = Math.abs((this.state.height - currentMediaScales.height) / 2) + 2;

    if (zoomOffset.x < -boundryX) {
      zoomOffset.x = -boundryX;
    }
    if (zoomOffset.x > boundryX) {
      zoomOffset.x = boundryX;
    }
    if (zoomOffset.y < -boundryY) {
      zoomOffset.y = -boundryY;
    }
    if (zoomOffset.y > boundryY) {
      zoomOffset.y = boundryY;
    }

    this.setState({zoomOffset});
  }

  private handleClick() {
    this.clicks++;
    /* istanbul ignore next */
    setTimeout(() => this.clicks--, 500);
    if (this.clicks % 2 === 0) {
      this.toggleZoom();
    }
  }

  private toggleZoom() {
    this.currentTouch = {x: 0, y: 0};
    this.setState(state => ({zoom: !state.zoom, zoomOffset: {x: 0, y: 0}}));
  }

  private navigatePrev() {
    const {currentIndex, handleNavigateTo, media} = this.props;
    const to = currentIndex > 0 ? currentIndex - 1 : media.length - 1;
    handleNavigateTo(to);
  }

  private navigateNext() {
    const {currentIndex, handleNavigateTo, media} = this.props;
    const to = currentIndex < media.length - 1 ? currentIndex + 1 : 0;
    handleNavigateTo(to);
  }

  private renderArrows() {
    if (this.props.isMobile) {
      return;
    }

    const {currentIndex, media} = this.props;
    const withPrev = currentIndex > 0;
    const withNext = currentIndex < media.length - 1;

    return (
      <>
        {withPrev && (
          <a data-hook={Hooks.ArrowPrev} className={cx(s.arrow, s.prev)} onClick={() => this.navigatePrev()}>
            <ArrowLeft />
          </a>
        )}
        {withNext && (
          <a data-hook={Hooks.ArrowNext} className={cx(s.arrow, s.next)} onClick={() => this.navigateNext()}>
            <ArrowRight />
          </a>
        )}
      </>
    );
  }

  private warmUp() {
    this.props.media.forEach(m => {
      const url = getMediaUrl(m, this.getScaledMedia(m, this.zoomFactor), this.mediaOptions);
      this.warmed.push(url);
      new Image().src = url;
    });
  }
}
