import React from 'react';
import cx from 'classnames';
import s from './ProductPrice.scss';
import {ProvidedGlobalProps, withDirection, withGlobalProps} from '../../../providers/globalPropsProvider';
import {LayoutContext} from '../../ProductPageApp/ProductPageApp';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISubscriptionPlan} from '../../ProductOptions/SubscriptionPlans/SubscriptionPlan/SubscriptionPlan';

export interface ProductPriceProps extends ProvidedGlobalProps {
  price: number;
  comparePrice?: number;
  formattedPrice: string;
  formattedComparePrice?: string;
  className?: string;
  plan?: ISubscriptionPlan;
}

@withGlobalProps
@withTranslations('globals.texts')
@withDirection
export class ProductPrice extends React.Component<ProductPriceProps & IProvidedTranslationProps> {
  private renderPrimary() {
    const {formattedPrice, comparePrice, t, globals, plan} = this.props;
    const shouldShowSubscriptionPlanFrequancy = globals.experiments.isSubscriptionPlansEnabled && plan;
    const primaryTranslationKey = comparePrice
      ? 'SR_PRODUCT_PRICE_AFTER_DISCOUNT'
      : 'SR_PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT';

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-primary-price" className={s.primary}>
          {formattedPrice}
        </span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(primaryTranslationKey)}
        </span>
        {shouldShowSubscriptionPlanFrequancy && (
          <span data-hook="subscription-plan-frequency" className={s.subscriptionPlanFrequency}>
            &nbsp;
            {t('PRODUCT_PAGE_SUBSCRIPTION_PRICE_DESCRIPTION', {
              frequencyUnitSingular: this.getFrequencyUnitSingularTranslation(),
            })}
          </span>
        )}
      </div>
    );
  }

  private getFrequencyUnitSingularTranslation(): string {
    const {t, plan} = this.props;

    switch (plan.frequency) {
      case 'DAY':
        return 'DAY_QA';
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_WEEK');
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_MONTH');
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_YEAR');
    }
  }

  private renderSecondary() {
    const {formattedComparePrice, t} = this.props;

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-secondary-price" className={s.secondary}>
          {` ${formattedComparePrice} `}
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('SR_PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </div>
    );
  }

  public render() {
    const {price, comparePrice, className} = this.props;

    if (!price) {
      return null;
    }

    return (
      <LayoutContext.Consumer>
        {layoutName => (
          <div data-hook="product-price-wrapper" className={cx(className, s.prices, s[`prices__${layoutName}`])}>
            {comparePrice > 0 && this.renderSecondary()}
            {this.renderPrimary()}
          </div>
        )}
      </LayoutContext.Consumer>
    );
  }
}
