import * as React from 'react';
import * as _ from 'lodash';
import * as s from './ResponsiveGallery.scss';
import classNames from 'classnames';
import {IProduct, IMedia, IVideoFile} from '../../../../types/productDef';
import {ImageMode, ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {MediaType} from '@wix/wixstores-client-core/dist/es/src/types';
import {Swipeable} from 'react-swipeable';
import {getMediaUrl, getFullSizedMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {NoProduct} from '../../../../icons/dist';
import {DotNavigation} from 'wix-ui-tpa/DotNavigation';

const WIXVIDEO_BASEURL = 'https://video.wixstatic.com/';

export enum ResponsiveGalleryDataHook {
  NavigationButton = 'responsive-gallery-navigation',
  Media = 'responsive-gallery-media',
  NoMedia = 'responsive-gallery-no-media',
}

export interface ResponsiveGalleryProps {
  media: IProduct['media'];
  imageMode: ImageMode;
}

interface ResponsiveGalleryState {
  currentIndex: number;
  ratio: number;
  currentEffect: 'fadeout' | 'fadein';
  media: IProduct['media'];
  renderWidth: number;
}

export class ResponsiveGallery extends React.Component<ResponsiveGalleryProps, ResponsiveGalleryState> {
  private readonly ref: React.RefObject<HTMLDivElement>;
  private readonly defaultRenderWidth = 100;

  public constructor(props) {
    super(props);
    this.ref = React.createRef();
    const {media} = this.props;
    const ratio = media[0] ? Math.ceil((media[0].height / media[0].width) * 100) : 100;
    this.state = {
      currentEffect: null,
      currentIndex: 0,
      media: [],
      ratio,
      renderWidth: this.defaultRenderWidth,
    };
  }

  private readonly navigateToMedia = (currentIndex: number) => {
    if (this.props.media.length <= 1) {
      return;
    }

    this.setState({currentEffect: 'fadeout'});
    setTimeout(() => {
      this.setState({currentIndex}, () => {
        setTimeout(() => {
          this.setState({currentEffect: 'fadein'});
        }, 100);
      });
    }, 100);
  };

  private readonly goRight = () => {
    let nextIndex = this.state.currentIndex - 1;
    if (nextIndex === -1) {
      nextIndex = this.props.media.length - 1;
    }
    this.navigateToMedia(nextIndex);
  };

  private readonly goLeft = () => {
    let nextIndex = this.state.currentIndex + 1;
    if (nextIndex > this.props.media.length - 1) {
      nextIndex = 0;
    }
    this.navigateToMedia(nextIndex);
  };

  private readonly renderMedia = () => {
    const {ratio, currentEffect, currentIndex} = this.state;
    const {media, imageMode: imageMode} = this.props;
    const currentMedia = media[currentIndex];
    const src = getMediaUrl(currentMedia, this.getMediaDimensions(currentMedia));
    const video = currentMedia.mediaType === MediaType.VIDEO && currentMedia.videoFiles && currentMedia.videoFiles[0];
    const backgroundImage = video ? 'none' : `url(${src})`;
    const backgroundSize = imageMode === ImageModeValues.CROP ? 'cover' : 'contain';

    return (
      <div className={s.mediaContainer}>
        <Swipeable onSwipedLeft={this.goLeft} onSwipedRight={this.goRight} trackMouse={true}>
          <div
            data-hook={ResponsiveGalleryDataHook.Media}
            className={classNames(s.media, s[currentEffect])}
            style={{backgroundImage, backgroundSize, paddingTop: `${ratio}%`}}>
            {video && this.renderVideo(video)}
          </div>
        </Swipeable>
      </div>
    );
  };

  private renderVideo(video: IVideoFile) {
    const src = WIXVIDEO_BASEURL + video.url;
    return <video controls src={src} />;
  }

  private readonly renderNavigation = () => {
    const {media} = this.props;
    const {currentIndex} = this.state;

    if (media.length <= 1) {
      return;
    }

    return (
      <div className={s.navigation}>
        <DotNavigation
          currentIndex={currentIndex}
          onSelect={i => this.navigateToMedia(i)}
          data-hook={ResponsiveGalleryDataHook.NavigationButton}
          length={media.length}
        />
      </div>
    );
  };

  public static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.media, state.media)) {
      return {currentIndex: 0, media: props.media};
    }
    return null;
  }

  public componentDidMount() {
    this.setState({renderWidth: this.ref.current.clientWidth});
  }

  public render() {
    const {media} = this.props;
    return (
      <div data-hook="responsive-gallery" ref={this.ref}>
        {media.length > 0 ? this.renderMedia() : this.renderNoMedia()}
        {this.renderNavigation()}
        {this.renderSEO()}
      </div>
    );
  }

  private renderNoMedia() {
    return (
      <div data-hook={ResponsiveGalleryDataHook.NoMedia} className={s.noMedia}>
        <NoProduct />
      </div>
    );
  }

  private getMediaDimensions(media: IMedia) {
    const width = this.state.renderWidth || this.defaultRenderWidth;
    const height = (media.height / media.width) * width;
    return {height, width};
  }

  private renderSEO() {
    const {media} = this.props;
    return (
      <ul className={s.seo_only}>
        {media.map(m => (
          <li key={`media-${m.url}`}>
            <img src={getFullSizedMediaUrl(m)} />
          </li>
        ))}
      </ul>
    );
  }
}
